.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Make the container full-height */
  }
  
  .login-box {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 20px;
    
    text-align: center;
    border-radius: 5px;
    width: 300px; /* Adjust the width as needed */
  }
  
  h2 {
    margin-bottom: 20px;
  }
  .i1{
    width:200px;
    height:30px;
    margin-left: 50px;
    border-radius: 10px;
  }
  .i2{
width:200px;
height:30px;
margin-left: 50px;
border-radius: 10px;

/* padding: 40px; */

  }
 
  /* .input { */
    /* width:170px;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: black;  */
    /* border-top: white;Remove default borders */
    /* outline: none; Remove focus outline */
   
    /* border-radius: 3px; */
    /* flex-grow: 1; Expand inputs to fill available space */
    /* border: none; /* Remove default borders 
    /* outline: none; Remove focus outline */ 
    /* padding: 0; Remove default padding */
    /* margin: 20px; Remove default margin */
  
  /* } */
  
  button {
    width: 100%;
    padding: 10px;
    background-color:#76c045 ;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #76c045;
  }
  .login-container{
    font-family: 'Comfortaa',Regular;
  }
  
.phone-input {
    /* width:170px; */
    display: flex; /* Make child elements appear in a row */
    align-items: start;
     /* Vertically align items */
  }
  
  .phone-input input {
    margin: 15px;
 
    margin-top: 40px;
    /* margin-left: 100px; */
 /* flex-grow: 1;  */
    border: none; /* Remove default borders */
    outline: none; /* Remove focus outline */
    padding: 0; /* Remove default padding */
    /* margin: 20px; Remove default margin */
  }
  
  .divider {
    border: none; /* Remove default border */
    border-top: 1px solid #ccc; /* Add a 1px horizontal line */
    margin:0px/* Adjust margin for spacing */
  }
  .b1{
    width:80px;
    border-radius: 15px;
    margin-top: 20px;
  }
  .l{
    font-size: small;
    color: black;
  }