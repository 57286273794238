/* .mainbody{
    height: 200px;
    width:100%;
    background-color: rgb(23, 7, 85);
} */


/* green: #76c045;
RED : #f72e42;
GREY : #3e4c4c; */


/* Add this CSS to your existing stylesheet */

/* Style for the responsive menu toggle button */

/* .header{
    color: #3e4c4c;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
} */




.custom-navbar-height {
  height: 60px; /* Adjust the height as needed */
}
@media (max-width: 768px) {
  .custom-nav-link .div1 {
    height: 55px;
    font-size: 15px;
    width: 55px;
    margin: 2px 1px;
    font-weight: bold;
  }
  .custom-navbar-height {
    height: 60px; /* Adjust the height as needed */
  }
}


.custom-nav-link{
  color: #FFFFFF;
  font-size: 16px;
  width: 75px;
  padding: 10px 20px;
  margin: 0 10px;
  text-decoration: none;
  /* color: #333; */
  
  
  }
  
  
  
  @media (max-width:100%) {
    .custom-nav-link {
      padding: 20px 20px;
       /* Adjust the padding values as needed */
    }
  }
  
  /* Optionally, you can also adjust font-size for smaller screens */
  @media (max-width: 768px) {
    .custom-nav-link {
      font-size: 14px;
      /* height:55px; */
      /* margin-left: -30px; */
      /* Adjust the font size as needed */
    }
  }
  
  
  
  
    
  .subbody{
      /* Replace with your image path */
      background-size:contain;
       background-color: white;
      /* background: linear-gradient(to top,#f72e42
       ,white); */
      background-position: start;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
     
      align-items: center;
      justify-content: flex-start;
      color: white; /* Text color */
      background-repeat: no-repeat;
  }
  /* Apply a reset to remove default margin and padding from elements */
  
  
    /* Add separation between custom-nav-link elements */
  
    /* Style the highlighted link with a different background color */
  
    /* Style the links container with a slightly lighter background color */
  /* Style the links container with a slightly lighter background color */
  /* CSS to style the span elements */
  .span1 {
      color: #FFFFFF; /* Text color */
      background-color:#f72e42; /* Background color */
      padding: 5px 10px; /* Padding around the span text */
      border-radius: 15px; /* Rounded corners */
      font-size: 14px; /* Font size */
      font-weight: bold; /* Font weight */
      text-decoration: none; /* Remove underline */
    }
    
    /* Hover effect for the span elements */
    .span1:hover {
      background-color:#f09d9d ; /* Change background color on hover */
      /* You can add other hover styles as needed */
    }
  
    /* navpage.css */
  /* Add this CSS to your navpage.css file */
  .active-link .div1 {
    background-color: #f72e42; /* Change to your desired active link color (red) */
    color: white; /* Change text color for active link */
  }
  
    .image4{
      height:20px;
      width: 20px;
      padding: auto;
    }
    .image41{
      height:30px;
      width: 30px;
      padding: auto;
    }
    .centered-links {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0px;
      height: 100%;
      
    }
    .divalign{
      padding-left: 0px;
    }
    .image2{
      height:50px;
      width: 50px;
      /* margin-left: 10px; */
      font-family: 'Comfortaa', cursive;
      font: bolder;
      /* margin-top: 10px; */
    }
    .header{ 
     
      font-family: 'Comfortaa', cursive;
  font-size: 30px;
      color: #3e4c4c;
      /* margin-top: 20px; */
      
    }
  
    .dropdown-link:hover {
      background-color:#f72e42; /* Change to your desired hover background color */
      color: #fff; /* Change to your desired hover text color */
    }
    /* changed */
    .full-width-navbar{
      background-color:#76c045;
      /* padding-left: 10px; */
   
      
    
    
    }
    @media (max-width: 768px) {
      .full-width-navbar{
       
        height:70px;
        /* margin-left: -30px; */
        /* Adjust the font size as needed */
      }
    }
    
    .full-width-navbar1{
      background-color:white;
      
    }
    .first{
   margin-left: 550px;
   font-family: 'Comfortaa', cursive;
   font-size: 20px;
   color: #3e4c4c;
   text-decoration: none;
    }
    .second{
      margin-left: 20px;
      font-family: 'Comfortaa', cursive;
      font-size: 20px;
      color: #3e4c4c;
      text-decoration: none;
       }
      
   .navalign{
    
   }
    .div1{
      background-color: white;
    height: 90px;
    font-family: 'Comfortaa', regular;
    /* Adjust the width and padding as needed */
    width: 100px; /* Adjust the width */
    margin: 15px 10px;
    text-align: center;
    font-size: 20px;
    color: #3e4c4c;
    font-weight: bold; /* Make the font bold */
  border-radius: 15px;
    }
    @media (max-width: 768px) {
      .div1 {
        height: 55px;
      font-size: 15px;
      font-family: 'Comfortaa', regular;
      width: 55px; /* Adjust the width to your desired value */
        /* margin-left: -15px; Add some padding to maintain spacing */
     /* padding-left: ; */
     
        text-align: center;
        color: #3e4c4c;
        margin: 2px 1px; /* Maintain spacing between links */
        font-weight: bold; /* Make the font bold */

      }
    }
    
    
    .mapstyle {
      width: 75%;
      height: 500px;
      margin: 0 auto; /* This will center the element horizontally */
      text-align: center; /* This will center the content inside the element */
  }
  
    .navbar-container{
      height:80px;
      width: 100%;
    }


    /* Center align the table */
    /* .table-container {
      text-align: center;
      margin-left: 600px;
    } */
    /* .table-container {
      overflow-x: auto;
      max-width: 100%;
      
    } */

    .table-container {
      width: 50%;
      margin: 0 auto; /* Center align the table */
      text-align: center; /* Center align the text within the table cells */
    }
    
    @media (max-width: 720px) {
      /* Apply specific styles for screens smaller than 768px (e.g., mobile) */
      .table-container {
       margin-left: 150px;
       width: auto;
      }
    }
    
    
    @media (max-width: 1200px) {
      .table-container {
        overflow-x: auto;
        max-width: 100%;
      }
    }
    
    /* @media (max-width: 768px) {
      .table-container {
        margin-left: 0;
      }
    } */
/* Style the table headings */
.table-container th {
  font-weight: bold;
}

/* Add column borders to the table */
.table-container th,
.table-container td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* Add padding to the table cells */
.table-container td {
  padding: 10px;
}

/* Style the social media icons */
.social-icons {
  text-align: center;
  margin-bottom: 20px;
}

.social-icons a {
  margin: 0 10px;
}
/* Common styles for all screen sizes */
.subtitle {
  font-size: 14px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.place-card {
  width: 100%; /* Cards take full width on small screens */
  margin-bottom: 20px;
}

/* Adjustments for screens with a width up to 720px */
@media only screen and (max-width: 720px) {
  .card-container {
    flex-direction: column; /* Stack cards in a column on small screens */
  }

  .place-card {
    width: 100%; /* Cards take full width on small screens */
    padding: 10px; /* Add padding for better spacing */
  }

  img {
    max-width: 100%; /* Make images responsive within their containers */
  }

  /* Additional styles for 720x1600 resolution */
  body {
    font-size: 12px; /* Adjust the overall font size for better readability */
  }

  .tab-link {
    font-size: 12px; /* Adjust the font size of tab links */
  }

  .go {
    font-size: 10px; /* Adjust the font size of 'Go' text */
  }

  /* Add more styles as needed */
}
@media only screen and (max-width: 720px) {
 

  .table-container {
    overflow-x: auto; /* Enable horizontal scrolling for the table on small screens */
  }

  .table-container th,
  .table-container td {
    padding: 5px; /* Add padding to table cells for better spacing on small screens */
  }

  /* Add more styles as needed */
}
@media (max-width: 768px) {
  .image10{
    height:100px;
      float: left;
       padding-right:10px ; 
  }}
  .image10{
    height:200px;
    width: 45px; 
      float: left;
       padding-right:30px ; 
  }


  .iframe-container {
    margin-top: 20px;
    text-align: center; /* Ensure the iframe is centered */
    width:100%;
  }
  
  .responsive-iframe {
    width: 80%; /* Default width for desktop */
    height: 600px;
    margin: 0 auto; /* Center the iframe */
    display: block;
    border: none;
  }
  
  /* Media query for laptop devices */
  @media (min-width: 900px) and (max-width: 1024px) {
    .responsive-iframe {
      width: 100%; /* Adjusted width for laptops */
      height: 500px; /* Adjusted height for laptops */
    }
  }
  
  /* Media query for larger system devices */
  @media (min-width: 1025px) {
    .responsive-iframe {
      width: 60%; /* Adjusted width for larger system devices */
      height: 700px; /* Adjusted height for larger system devices */
    }
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .responsive-iframe {
      width: 100%; /* Full width for mobile */
      height: 400px; /* Adjust height if needed for mobile */
    }
  }
  