.bg1{
  background-color: #e3f2da;
  height:150px;
  font-family: 'Comfortaa',bold,Regular;
 

}
.hstyle{
  padding-top: 40px;
  font-size: 15px;
}
.hstyle1{
 
  font-size: 15px;
}
.bgmain{
  background-color: aliceblue;
  height: 900px;
 
}
.trip-list {
  display: flex;
  flex-direction: column; /* Stack items vertically */
}

.det{
margin-top: 50px;
  height:250px;
  width:70%;
  background-color: #e3f2da;
 margin-left: 200px;
 
  /* display: flex; */
  gap: 10px; /* Add a gap between each trip */
  align-items: center; /* Center items horizontally */
  margin-bottom: 10px; /* Add space at the bottom of each trip */

}
.l1{
color: black;
background-color:rgb(213, 196, 149);
width: 600px;
height:50px;
}
.sp{
  background-color: rgb(246, 233, 197); /* Set your desired background color */
  width: 600px; /* Set the width to your desired value */
  display: inline-block; /* This ensures that the width property works as expected */
  padding: 5px;
  height:40px;
}
.sa {
  display: flex; /* Use flexbox layout for responsiveness */
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
  justify-content: space-around; /* Distribute items with equal space around them */
}
.container-style {
  border-top: 1px dotted black;
  background-color: rgb(246, 233, 197);
  padding: 10px;
  padding-left: 0; 
}
/* Media query for mobile view */
@media (max-width: 600px) {
  .container-style {
    padding: 5px; /* Adjust padding for smaller screens */
    padding-left: 0; 
    font-size: 0.9em; /* Optional: Adjust font size */
  }
}
@media (max-width: 768px) {
  .container-style {
    padding: 5px; /* Adjust padding for smaller screens */
    padding-left: 0; 
    font-size: 0.9em; /* Optional: Adjust font size */
  }
}
@media (max-width: 768px) {
  .trip-list {
    width: 45%; /* Adjust the width for medium-sized screens */
  }
}

@media (max-width: 768px) {
  .trip-list {
    width: 100%; /* Full width for smaller screens */
  }
}
@media (max-width: 600px) {
  .sp{
    width: 210px;
    height:auto
  }
}
@media (max-width: 768px) {
  .sp{
    width: 210px;
    height:auto
  }
}
@media (max-width: 768px) {
.det{  
  width: 100%;
  margin-left: 2px;
  height:auto

}}
@media (max-width: 600px) {
  .det{  
    width: 100%;
    margin-left: 2px;
    height:auto
  
  }}
.image12{
  height:100px;
  width: 45px; 
    float: left;
     padding-right:30px ; 
     margin-top:60px;
}
@media (max-width: 768px) {
  .image{
    height:100px;
      float: left;
       padding-right:10px ; 
  }}
.image{
  height:200px;
    float: left;
     padding-right:50px ; 
}
.image1{
height:60px;
width:50px;
float: left;

}
.t1{
text-align: center;
}
.trip-details{
margin-left: 10px;
height: 300px;
}
