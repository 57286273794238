.about{
    text-align: center;
    font-size: 30px;
    font-family: 'Comfortaa',bold,Regular;
    color:black;
}


/*Add this CSS to your stylesheet */
/* .about {
  display: flex; /* Use flexbox to arrange content horizontally */
 /* justify-content: space-between; /* Space between the two main content sections */
 /* align-items: center; /* Vertically center content */
 /* padding: 20px;  Add padding for spacing */
/*}

.content-container {
  display: flex; /* Use flexbox to arrange content horizontally */
  /*align-items: center; /* Vertically center content within the container */
/*}



.left-content {
    flex: 1; /* Grow to take available space */
    /*  padding: 20px; Add padding for spacing */ 
    .img1 {
        height: auto; /* Adjust the height as needed */
        width: 80%; /* Auto-adjust the width to maintain aspect ratio */
      }
      .b13{
       margin-top: 10px;
        height:40px;
        width: 100px;
        border-radius: 15px;
        border-color: black;
      }
      .sp12{
        margin: 20px;
        color: #76c045;
        text-align: center;
        font-size: large;
      }

      .align {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
  
      .align img {
        display: block;
      }
  
      .align p {
        text-align: center;
        margin: 0;
      }
      .imagesocial{
        height:'10px';
        width:'10px';
        padding:'3px'
      }
      .b12{
        text-align: center;
        margin: 0%;
        height:20px;
        width:70px;
        background-color: transparent;
        color: black;
        font-size: 10px;
        border-radius: 15px;
        border-color: black;
      }
      .p1{
        font-size: 15px;
        text-align: start;
        margin-left: 40px;
      }
      .left-content{
        flex:1;
        padding: 20px;
      }
  
.right-content {
  flex: 1; /* Grow to take available space */
  padding: 20px; /* Add padding for spacing */
  text-align: right; /* Align content to the right */
}

/* Additional styles for the Navbar and other elements can be added here */



.h11{
    text-align: center;
    font-size: 30px;
    font-family: 'Comfortaa',bold,Regular;
    color:black;
}
.h12{
    color:#76c045;
}
/* Add this to your existing CSS or create a new CSS file */

.contact-us {
  margin: 50px auto;
  text-align: center;
}

.contact-us h1 {
  color: #f72e42;
}

.contact-us p {
  margin: 10px 0;
}

.social-media-icons {
  text-align: center;
  margin: 20px 0;
}

.imagesocial {
  margin: 0 10px;
}

/* Media Query for responsiveness */
@media (max-width: 768px) {
  .contact-us {
    margin: 20px auto;
  }

  .contact-us h1 {
    font-size: 24px;
  }

  .contact-us p {
    font-size: 14px;
  }

  .social-media-icons {
    margin: 10px 0;
  }

  .imagesocial {
    width: 20px;
  }
}
