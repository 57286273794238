

/* green: #76c045;
RED : #f72e42;
GREY : #3e4c4c; */

.h11{
    text-align: center;
    font-size: 30px;
    font-family: 'Comfortaa',bold,Regular;
    color:black;
}
.h12{
    color:#76c045;
}
.h41{
    color:#3e4c4c;
   margin-left: 25px;
}
.sp1{
    color:white;
    background-color: #76c045;
   margin-left: 50px;
}
.contact-form {
    text-align: center;
    width:350px;
    margin-left: 400px;
}

.form-heading {
    font-size: 24px;
    font-weight: bold;
}

.form-subheading {
    font-size: 18px;
    margin-top: 10px;
}

.form-label {
    display: block;
    margin-top: 10px;
    text-align: left;
}

.form-input {
    width: 100%;
    height:30px;
    padding: 10px;
    margin-top: 5px;
}

@media screen and (max-width: 600px) {
   
    .contact-form {
        width: 50%;
        margin-left: 100px; /* Make the form width 90% of the viewport width */
        /* margin: 0 auto; Center the form horizontally */
    }

    .form-heading {
        font-size: 20px; /* Reduce font size for headings on small screens */
    }

    .form-subheading {
        font-size: 16px; /* Reduce font size for subheading on small screens */
    }

    .form-label {
        font-size: 14px; /* Reduce font size for labels on small screens */
    }

    .form-input {
        font-size: 14px;
        height:auto;
        border-color: #3e4c4c; /* Reduce font size for input fields on small screens */
    }
}