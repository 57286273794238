/* Style for the button container */
.button-container {
    margin-top: 15px;
    display: flex; /* Display buttons horizontally */
    gap: 15px; /* Add a gap between buttons */
  }
  
  /* Style for the buttons */
  .button {
    background-color: white; /* Green background color */
    height: 30px;
    width: 70px;
    border: none;
    border-radius: 25px; /* Rounded corners */
    color: #3e4c4c;
    padding: 5px 10px; /* Padding inside buttons */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  }
  
  /* Change background color and text color on hover */
  .button:hover {
    background-color:#76c045;
    color: #3e4c4c;
  }
  
  /* Buses.css */
  /* Add this CSS to your existing stylesheet or create a new one */
  
  /* trip.css */
  /* Add this CSS to your existing stylesheet or create a new one */
  
  /* Style the trip-container */
  .trip-container {
    text-align: center;
    margin-top: 10px;
  }
  
  /* Style the heading */
  .trip-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color:#3e4c4c;
  }
  
  /* Style the input-container */
  .input-container {
    margin-bottom: 15px;
    /* Add margin to separate input elements vertically */
  }
  
  /* Default styles for input fields */
.input-container input {
  padding: 10px;
  width: 100%;
  border: 1px solid #333;

}

/* Responsive styles for larger screens */
@media (min-width: 768px) {
  .input-container input {
    width: 50%; /* Adjust the width as needed for larger screens */
  }
}

/* Responsive styles for even larger screens */
@media (min-width: 1024px) {
  .input-container input {
    width: 500px; /* Adjust the width as needed for even larger screens */
  }
}

/* Responsive styles for small screens (e.g., mobile devices) */
@media (max-width: 480px) {
  .input-container input {
    width: 90%; /* Adjust the width as needed for small screens */
    margin: 0 auto; /* Center the input fields horizontally */
  }
}
  
  /* Style the search button */
  .search-button {
    margin-top: 20px;
    background-color: #f72e42;
    color: white;
    padding: 10px 20px;
    border: none;
   width:150px;
    cursor: pointer;
    box-shadow: 0px 0px 20px rgba(255, 251, 251, 0.874);
  }
  
  /* Add hover effect for the search button */
  .search-button:hover {
    background-color: #f72e42;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Add shadow */
  }
  
  .button.active {
    background-color:#76c045;
    color: #3e4c4c;
  }
  .select-control {
    width: 500px;
  }
  
  /* Customize the color of the options */
  .select-menu-list {
    color: black;
  }

  .go{
    background-color: #76c045;
    color: white;
  }



  /* Marquee animation */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* Marquee container styles */
.marquee-container {
  width: 100%;
  background-color: #76c045;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 30px;
}

/* Marquee content styles */
#marquee-content {
  font-style: italic;
  font-weight: bold;
  font-size: 2em; /* Adjust the font size as needed */
  @media (max-width: 768px) {
    font-size: 1.2em; /* Adjust the font size for smaller screens */
   
  }
  animation: marquee 10s linear infinite;
}